// @flow

import React from 'react'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import { useIntl } from 'gatsby-plugin-intl'
import { Container } from '../../../components/basic/Grid'
import baseStyles from './StrapiTeaser.scss'
import classNames from 'classnames'

type Props = {
  ...StyleProps,
  title: string,
  text: string,
  img: any,
  reversed?: boolean,
  titlePlain?: boolean
}

const StrapiTeaser = ({ styles, title, img, text, reversed, titlePlain }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <div className={classNames(styles.root, reversed && styles['is-reversed'])}>
      {reversed ? (
        <Container className={styles.container} fluid>
          <aside className={styles.containerRight}>
            <div className={styles.images}>
              <div className={styles.imageBig}>{img}</div>
            </div>
          </aside>
          <div className={styles.containerLeft}>
            <h2
              className={classNames(styles.title, titlePlain && styles.titlePlain)}
              dangerouslySetInnerHTML={{ __html: formatMessage(title) }}
            />
            <span className={styles.text} dangerouslySetInnerHTML={{ __html: formatMessage(text) }} />
          </div>
        </Container>
      ) : (
        <Container className={styles.container} fluid>
          <div className={styles.containerLeft}>
            <h2
              className={classNames(styles.title, titlePlain && styles.titlePlain)}
              dangerouslySetInnerHTML={{ __html: formatMessage(title) }}
            />
            <span className={styles.text} dangerouslySetInnerHTML={{ __html: formatMessage(text) }} />
          </div>
          <aside className={styles.containerRight}>
            <div className={styles.images}>
              <div className={styles.imageBig}>{img}</div>
            </div>
          </aside>
        </Container>
      )}
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(StrapiTeaser)
