// @flow

import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import Layout from '../../../components/Layout/index'
import Header from '../../../modules/Header'
import Footer from '../../../modules/Footer'
import SEO from '../../../components/seo'
import LandingpageNav from '../../../components/Landingpage/LandingpageNav'
import CustomContactBtn from '../../../components/Landingpage/CustomContactBtn'
import Landingpage from '../../../components/LandingpageExtended'
import messages from '../../../messages/pageTitles.lang'
import metaDescription from '../../../messages/pageMetaDescriptions.lang'

import baseStyles from './software.scss'
import baseLandingpageMessages from '../../../components/LandingpageExtended/Landingpage.lang'

import { StaticImage } from 'gatsby-plugin-image'

/* eslint-disable max-len */
const heroImg = (
  <StaticImage src="../../../images/landingpage/heroteaser_background.jpg" alt="" objectFit="cover" className="image" />
)
const descriptionImgSmall1 = (
  <StaticImage src="../../../images/landingpage/discussion-meeting.jpg" alt="" objectFit="cover" className="image" />
)
const descriptionImgSmall2 = (
  <StaticImage src="../../../images/landingpage/men-explaining-laptop.jpg" alt="" objectFit="cover" className="image" />
)
const benefitsImg = (
  <StaticImage src="../../../images/landingpage/guy-staring.jpg" alt="" objectFit="cover" className="image" />
)
const benefitsImg2 = (
  <StaticImage src="../../../images/landingpage/complicatedstatistics.jpg" alt="" objectFit="cover" className="image" />
)
const descriptionImgBig = (
  <StaticImage
    src="../../../images/landingpage/woman_eating_pencil.jpg"
    alt=""
    objectFit="cover"
    className="imageCover"
  />
)
const imgStrapi = (
  <StaticImage src="../../../images/landingpage/woman_touching_screen.jpg" alt="" objectFit="cover" className="image" />
)
const imgTestModule = (
  <StaticImage src="../../../images/landingpage/thinkoutsidethebox.jpg" alt="" objectFit="cover" className="image" />
)
/* eslint-enable max-len */

type Props = {
  ...StyleProps
}

const data = {
  heroTeaser: {
    img: heroImg,
    title: baseLandingpageMessages.heroTitle,
    subtitle: baseLandingpageMessages.heroSubtitle
  },
  descriptionTeaser: {
    title: baseLandingpageMessages.descriptionTitle,
    text: baseLandingpageMessages.descriptionText,
    displayButton: false,
    button: baseLandingpageMessages.descriptionButton,
    imgBig: descriptionImgBig,
    imgSmall1: descriptionImgSmall1,
    imgSmall2: descriptionImgSmall2
  },
  referencesExpanded: true,
  companyStatsDescription: {
    title: baseLandingpageMessages.testAlpha,
    text: baseLandingpageMessages.testBeta
  },
  stats: [
    {
      message: baseLandingpageMessages.stat1Message,
      value: baseLandingpageMessages.stat1Value,
      color: 'primary',
      icon: 'icon-goblet'
    },
    {
      number: 50,
      positive: true,
      numberDimension: baseLandingpageMessages.dimensionOthers,
      value: baseLandingpageMessages.stat2Value,
      color: 'tertiary',
      icon: 'icon-house'
    },
    {
      number: 10,
      positive: true,
      numberDimension: baseLandingpageMessages.dimensionYears,
      value: baseLandingpageMessages.stat3Value,
      color: 'secondary',
      icon: 'icon-star-filled'
    },
    {
      number: 300000,
      positive: true,
      numberDimension: baseLandingpageMessages.dimensionMillion,
      value: baseLandingpageMessages.stat4Value,
      color: 'accent',
      icon: 'icon-euro'
    }
  ],
  valueBox: {
    title: baseLandingpageMessages.valueBoxTitle,
    subtitle: baseLandingpageMessages.valueBoxSubtitle,
    title1: baseLandingpageMessages.valueBoxTitle1,
    title2: baseLandingpageMessages.valueBoxTitle2,
    title3: baseLandingpageMessages.valueBoxTitle3,
    values1: [
      baseLandingpageMessages.productValues1A,
      baseLandingpageMessages.productValues1B,
      baseLandingpageMessages.productValues1C,
      baseLandingpageMessages.productValues1D
    ],
    values2: [
      baseLandingpageMessages.productValues2A,
      baseLandingpageMessages.productValues2B,
      baseLandingpageMessages.productValues2C,
      baseLandingpageMessages.productValues2D
    ],
    values3: [
      baseLandingpageMessages.productValues3A,
      baseLandingpageMessages.productValues3B,
      baseLandingpageMessages.productValues3C,
      baseLandingpageMessages.productValues3D
    ]
  },
  valueBoxTwo: {
    title1: baseLandingpageMessages.valueBoxTitle1Two,
    title2: baseLandingpageMessages.valueBoxTitle2Two,
    title3: baseLandingpageMessages.valueBoxTitle3Two,
    values1: [
      baseLandingpageMessages.productValues1ATwo,
      baseLandingpageMessages.productValues1BTwo,
      baseLandingpageMessages.productValues1CTwo
    ],
    values2: [
      baseLandingpageMessages.productValues2ATwo,
      baseLandingpageMessages.productValues2BTwo,
      baseLandingpageMessages.productValues2CTwo
    ],
    values3: [
      baseLandingpageMessages.productValues3ATwo,
      baseLandingpageMessages.productValues3BTwo,
      baseLandingpageMessages.productValues3CTwo
    ]
  },
  benefitsTeaser: {
    img: benefitsImg,
    title: baseLandingpageMessages.benefitsTitle,
    titleTwo: baseLandingpageMessages.benefitsTitleAdditional,
    text: baseLandingpageMessages.benefitsText,
    additional: baseLandingpageMessages.benefitsTextAdditional,
    button: baseLandingpageMessages.benefitsButton,
    list: [
      baseLandingpageMessages.benefitsItemA,
      baseLandingpageMessages.benefitsItemB,
      baseLandingpageMessages.benefitsItemC
    ]
  },
  strapiTeaser: {
    title: baseLandingpageMessages.strapiTitle,
    titlePlain: true,
    text: baseLandingpageMessages.strapiText,
    img: imgStrapi
  },
  benefitsTeaserExtended: {
    img: benefitsImg2,
    title: baseLandingpageMessages.benefitsTitleExtended,
    text: baseLandingpageMessages.benefitsTextExtended,
    button: baseLandingpageMessages.benefitsButtonExtended,
    buttonTwo: baseLandingpageMessages.benefitsButtonExtendedTwo,
    reversed: true
  },
  TestModule: {
    title: baseLandingpageMessages.TestModuleTitle,
    text: baseLandingpageMessages.TestModuleSubtitle,
    buttonOne: baseLandingpageMessages.TestModuleButtonOne,
    buttonTwo: baseLandingpageMessages.TestModuleButtonTwo,
    additionalText: baseLandingpageMessages.TestModuleadditionalText,
    reversed: true,
    img: imgTestModule
  },
  contact: {
    title: baseLandingpageMessages.contactTitle,
    subtitle: baseLandingpageMessages.contactSubtitle,
    headline: baseLandingpageMessages.contactheadline
  }
}

const LandingSoftware = ({ styles }: Props) => {
  const { formatMessage, locale } = useIntl()

  return (
    <Layout
      footer={<Footer />}
      header={<Header withTeaser hasCustomNav customNav={<LandingpageNav />} contactBtn={<CustomContactBtn />} />}
    >
      <SEO
        title={formatMessage(messages.TitleSoftwareB)}
        description={formatMessage(metaDescription.softwaredevelopmentbDescription)}
        lang={locale}
      />
      <Landingpage data={data} styles={styles.landing} />
    </Layout>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(LandingSoftware)
