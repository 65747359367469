// @flow

import React from 'react'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import ContactForm from '../../ContactPage/ContactForm'
import baseStyles from './Contact.scss'
import { Container } from '../../../components/basic/Grid'
import { FormattedMessageSanitized } from '../../FormattedMessageSanitized'

type Props = {
  ...StyleProps,
  title: any,
  subtitle: any,
  headline: any
}

const Contact = ({ styles, title, headline, subtitle }: Props) => {
  return (
    <div className={styles.root}>
      <Container className={styles.container} fluid>
        <div className={styles.left}>
          <ContactForm formClassName={'contactBottom'} formHeadline={headline} />
        </div>
        <div className={styles.right}>
          <h2 className={styles.title}>
            <FormattedMessageSanitized {...title} />
          </h2>
          <h3 className={styles.subtitle}>
            <FormattedMessageSanitized {...subtitle} />
          </h3>
        </div>
      </Container>
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(Contact)
