// @flow

import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import { useIntl } from 'gatsby-plugin-intl'
import { Container } from '../../../basic/Grid'
import Collapsible from '../../../basic/Collapsible'
import Button from '../../../basic/Button'
import ClientLogo from '../../../Clients/Components/ClientLogo'

/* eslint-disable max-len */
const logo1 = (
  <StaticImage src="../../../../images/clientlogos/act-ahs.png" alt="" objectFit="contain" className="image" />
)
const logo2 = (
  <StaticImage
    src="../../../../images/clientlogos/act-airline-holidays.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const logo3 = (
  <StaticImage src="../../../../images/clientlogos/act-akbas.png" alt="" objectFit="contain" className="image" />
)
const logo4 = (
  <StaticImage src="../../../../images/clientlogos/act-allsun.png" alt="" objectFit="contain" className="image" />
)
const logo6 = (
  <StaticImage src="../../../../images/clientlogos/act-alltours.png" alt="" objectFit="contain" className="image" />
)
const logo7 = (
  <StaticImage src="../../../../images/clientlogos/act-anton-ritz.png" alt="" objectFit="contain" className="image" />
)
const logo8 = (
  <StaticImage src="../../../../images/clientlogos/act-apo-bank.png" alt="" objectFit="contain" className="image" />
)
const logo9 = (
  <StaticImage src="../../../../images/clientlogos/act-arteo.png" alt="" objectFit="contain" className="image" />
)
const logo10 = (
  <StaticImage src="../../../../images/clientlogos/act-barmenia.png" alt="" objectFit="contain" className="image" />
)
const logo11 = (
  <StaticImage src="../../../../images/clientlogos/act-bedfinder.png" alt="" objectFit="contain" className="image" />
)
const logo12 = (
  <StaticImage src="../../../../images/clientlogos/act-bref-henkel.png" alt="" objectFit="contain" className="image" />
)
const logo13 = (
  <StaticImage
    src="../../../../images/clientlogos/act-bucher-reisen.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const logo14 = (
  <StaticImage src="../../../../images/clientlogos/act-bz.png" alt="" objectFit="contain" className="image" />
)
const logo15 = (
  <StaticImage
    src="../../../../images/clientlogos/act-captain-racing-team.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const logo16 = (
  <StaticImage src="../../../../images/clientlogos/act-cbtl.png" alt="" objectFit="contain" className="image" />
)
const logo17 = (
  <StaticImage src="../../../../images/clientlogos/act-cool-kids.png" alt="" objectFit="contain" className="image" />
)
const logo18 = (
  <StaticImage src="../../../../images/clientlogos/act-delphin.png" alt="" objectFit="contain" className="image" />
)
const logo20 = (
  <StaticImage src="../../../../images/clientlogos/act-dertouristik.png" alt="" objectFit="contain" className="image" />
)
const logo21 = (
  <StaticImage src="../../../../images/clientlogos/act-dnvs.png" alt="" objectFit="contain" className="image" />
)
const logo22 = (
  <StaticImage src="../../../../images/clientlogos/act-drsb.png" alt="" objectFit="contain" className="image" />
)
const logo23 = (
  <StaticImage src="../../../../images/clientlogos/act-easyjet.png" alt="" objectFit="contain" className="image" />
)
const logo24 = (
  <StaticImage src="../../../../images/clientlogos/act-emirates.png" alt="" objectFit="contain" className="image" />
)
const logo25 = (
  <StaticImage src="../../../../images/clientlogos/act-erkes.png" alt="" objectFit="contain" className="image" />
)
const logo26 = (
  <StaticImage src="../../../../images/clientlogos/act-etihad.png" alt="" objectFit="contain" className="image" />
)
const logo27 = (
  <StaticImage src="../../../../images/clientlogos/act-findus.png" alt="" objectFit="contain" className="image" />
)
const logo29 = (
  <StaticImage src="../../../../images/clientlogos/act-florescence.png" alt="" objectFit="contain" className="image" />
)
const logo30 = (
  <StaticImage src="../../../../images/clientlogos/act-freshfocus.png" alt="" objectFit="contain" className="image" />
)
const logo31 = (
  <StaticImage src="../../../../images/clientlogos/act-frikadelli.png" alt="" objectFit="contain" className="image" />
)
const logo32 = (
  <StaticImage src="../../../../images/clientlogos/act-g-j.png" alt="" objectFit="contain" className="image" />
)
const logo33 = (
  <StaticImage
    src="../../../../images/clientlogos/act-general-henkel.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const logo34 = (
  <StaticImage src="../../../../images/clientlogos/act-geo.png" alt="" objectFit="contain" className="image" />
)
const logo35 = (
  <StaticImage src="../../../../images/clientlogos/act-globus.png" alt="" objectFit="contain" className="image" />
)
const logo37 = (
  <StaticImage src="../../../../images/clientlogos/act-henkel.png" alt="" objectFit="contain" className="image" />
)
const logo38 = (
  <StaticImage src="../../../../images/clientlogos/act-hlx.png" alt="" objectFit="contain" className="image" />
)
const logo39 = (
  <StaticImage src="../../../../images/clientlogos/act-hotelplan.png" alt="" objectFit="contain" className="image" />
)
const logo40 = (
  <StaticImage src="../../../../images/clientlogos/act-idas.png" alt="" objectFit="contain" className="image" />
)
const logo41 = (
  <StaticImage src="../../../../images/clientlogos/act-jansen.png" alt="" objectFit="contain" className="image" />
)
const logo42 = (
  <StaticImage src="../../../../images/clientlogos/act-ltur.png" alt="" objectFit="contain" className="image" />
)
const logo43 = (
  <StaticImage src="../../../../images/clientlogos/act-lufthansa.png" alt="" objectFit="contain" className="image" />
)
const logo44 = (
  <StaticImage src="../../../../images/clientlogos/act-marmor-otto.png" alt="" objectFit="contain" className="image" />
)
const logo45 = (
  <StaticImage src="../../../../images/clientlogos/act-metrostars.png" alt="" objectFit="contain" className="image" />
)
const logo46 = (
  <StaticImage
    src="../../../../images/clientlogos/act-migros-ferien.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const logo47 = (
  <StaticImage src="../../../../images/clientlogos/act-monkey.png" alt="" objectFit="contain" className="image" />
)
const logo48 = (
  <StaticImage src="../../../../images/clientlogos/act-motogadget.png" alt="" objectFit="contain" className="image" />
)
const logo49 = (
  <StaticImage src="../../../../images/clientlogos/act-mw.png" alt="" objectFit="contain" className="image" />
)
const logo50 = (
  <StaticImage
    src="../../../../images/clientlogos/act-neckermann-reisen.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const logo51 = (
  <StaticImage src="../../../../images/clientlogos/act-neomat.png" alt="" objectFit="contain" className="image" />
)
const logo52 = (
  <StaticImage src="../../../../images/clientlogos/act-oad-reizen.png" alt="" objectFit="contain" className="image" />
)
const logo53 = (
  <StaticImage src="../../../../images/clientlogos/act-olimar.png" alt="" objectFit="contain" className="image" />
)
const logo54 = (
  <StaticImage src="../../../../images/clientlogos/act-opodo.png" alt="" objectFit="contain" className="image" />
)
const logo55 = (
  <StaticImage src="../../../../images/clientlogos/logo-peakwork.png" alt="" objectFit="contain" className="image" />
)
const logo56 = (
  <StaticImage src="../../../../images/clientlogos/act-perwoll.png" alt="" objectFit="contain" className="image" />
)
const logo57 = (
  <StaticImage
    src="../../../../images/clientlogos/act-plattenplaner.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const logo58 = (
  <StaticImage src="../../../../images/clientlogos/act-profil-u.png" alt="" objectFit="contain" className="image" />
)
const logo59 = (
  <StaticImage src="../../../../images/clientlogos/act-promaterial.png" alt="" objectFit="contain" className="image" />
)
const logo60 = (
  <StaticImage src="../../../../images/clientlogos/act-promo-doro.png" alt="" objectFit="contain" className="image" />
)
const logo61 = (
  <StaticImage src="../../../../images/clientlogos/act-rasenqueen.png" alt="" objectFit="contain" className="image" />
)
const logo62 = (
  <StaticImage src="../../../../images/clientlogos/act-ratiopharm.png" alt="" objectFit="contain" className="image" />
)
const logo63 = (
  <StaticImage src="../../../../images/clientlogos/act-robinson.png" alt="" objectFit="contain" className="image" />
)
const logo64 = (
  <StaticImage
    src="../../../../images/clientlogos/act-roland-versicherung.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const logo65 = (
  <StaticImage
    src="../../../../images/clientlogos/act-ryanair-holidays.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const logo66 = (
  <StaticImage src="../../../../images/clientlogos/act-schulte.png" alt="" objectFit="contain" className="image" />
)
const logo67 = (
  <StaticImage src="../../../../images/clientlogos/act-seborin.png" alt="" objectFit="contain" className="image" />
)
const logo68 = (
  <StaticImage
    src="../../../../images/clientlogos/act-secret-escapes.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const logo69 = (
  <StaticImage src="../../../../images/clientlogos/act-sentido.png" alt="" objectFit="contain" className="image" />
)
const logo70 = (
  <StaticImage src="../../../../images/clientlogos/act-sil-henkel.png" alt="" objectFit="contain" className="image" />
)
const logo71 = (
  <StaticImage src="../../../../images/clientlogos/act-sixt.png" alt="" objectFit="contain" className="image" />
)
const logo72 = (
  <StaticImage src="../../../../images/clientlogos/act-spee-henkel.png" alt="" objectFit="contain" className="image" />
)
const logo73 = (
  <StaticImage src="../../../../images/clientlogos/act-speedbee.png" alt="" objectFit="contain" className="image" />
)
const logo74 = (
  <StaticImage src="../../../../images/clientlogos/act-ssm.png" alt="" objectFit="contain" className="image" />
)
const logo75 = (
  <StaticImage src="../../../../images/clientlogos/act-stern.png" alt="" objectFit="contain" className="image" />
)
const logo76 = (
  <StaticImage src="../../../../images/clientlogos/act-sterntaler.png" alt="" objectFit="contain" className="image" />
)
const logo77 = (
  <StaticImage src="../../../../images/clientlogos/act-stoehr.png" alt="" objectFit="contain" className="image" />
)
const logo78 = (
  <StaticImage
    src="../../../../images/clientlogos/act-swiss-holidays.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const logo79 = (
  <StaticImage
    src="../../../../images/clientlogos/act-tapper-hollmann.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const logo80 = (
  <StaticImage src="../../../../images/clientlogos/act-tembusch.png" alt="" objectFit="contain" className="image" />
)
const logo82 = (
  <StaticImage
    src="../../../../images/clientlogos/act-thomas-cook-hotels.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const logo84 = (
  <StaticImage src="../../../../images/clientlogos/act-tierschutz.png" alt="" objectFit="contain" className="image" />
)
const logo85 = (
  <StaticImage
    src="../../../../images/clientlogos/act-tierschutzverein.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const logo86 = (
  <StaticImage src="../../../../images/clientlogos/act-tripoli.png" alt="" objectFit="contain" className="image" />
)
const logo87 = (
  <StaticImage src="../../../../images/clientlogos/act-triton.png" alt="" objectFit="contain" className="image" />
)
const logo88 = (
  <StaticImage src="../../../../images/clientlogos/act-tui.png" alt="" objectFit="contain" className="image" />
)
const logo89 = (
  <StaticImage
    src="../../../../images/clientlogos/act-united_charity.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const logo90 = (
  <StaticImage
    src="../../../../images/clientlogos/act-urlaub-im-web.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const logo91 = (
  <StaticImage src="../../../../images/clientlogos/act-vernel.png" alt="" objectFit="contain" className="image" />
)
const logo92 = (
  <StaticImage src="../../../../images/clientlogos/act-vianido.png" alt="" objectFit="contain" className="image" />
)
const logo93 = (
  <StaticImage src="../../../../images/clientlogos/act-wc-frisch.png" alt="" objectFit="contain" className="image" />
)
const logo94 = (
  <StaticImage src="../../../../images/clientlogos/act-weekend.png" alt="" objectFit="contain" className="image" />
)
const logo95 = (
  <StaticImage src="../../../../images/clientlogos/act-weekengo.png" alt="" objectFit="contain" className="image" />
)
const logo96 = (
  <StaticImage
    src="../../../../images/clientlogos/act-weisser-riese.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const logo97 = (
  <StaticImage src="../../../../images/clientlogos/act-wickeder.png" alt="" objectFit="contain" className="image" />
)
const logo98 = (
  <StaticImage src="../../../../images/clientlogos/act-xtra.png" alt="" objectFit="contain" className="image" />
)
const logo101 = (
  <StaticImage src="../../../../images/clientlogos/logo-mts.png" alt="" objectFit="contain" className="image" />
)
const logo102 = (
  <StaticImage src="../../../../images/clientlogos/logo-arz.png" alt="" objectFit="contain" className="image" />
)
const logo103 = (
  <StaticImage
    src="../../../../images/clientlogos/act-condor-holidays.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const logo104 = (
  <StaticImage
    src="../../../../images/clientlogos/act-eurowings-holidays.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const logo106 = (
  <StaticImage src="../../../../images/clientlogos/act-jazhotels.png" alt="" objectFit="contain" className="image" />
)
const logo107 = (
  <StaticImage
    src="../../../../images/clientlogos/act-lastminute-ch.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const logo108 = (
  <StaticImage src="../../../../images/clientlogos/act-stewa.png" alt="" objectFit="contain" className="image" />
)
const logo109 = (
  <StaticImage src="../../../../images/clientlogos/logo-festo.png" alt="" objectFit="contain" className="image" />
)
const logo110 = (
  <StaticImage src="../../../../images/clientlogos/logo-mitsubishi.png" alt="" objectFit="contain" className="image" />
)
const logo111 = (
  <StaticImage src="../../../../images/clientlogos/logo-next.png" alt="" objectFit="contain" className="image" />
)

import logoDeichmanSrc from '../../../../images/clientlogos/deichmann-logo.svg'
const logoDeichman = <img src={logoDeichmanSrc} alt="" className="image" />
import logoTkSrc from '../../../../images/clientlogos/tk-logo.svg'
const logoTk = <img src={logoTkSrc} alt="" className="image" />
/* eslint-enable max-len */

import messages from './ClientOverview.lang'
import baseStyles from './ClientOverview.scss'
import { FormattedMessageSanitized } from '../../../FormattedMessageSanitized'

type Props = {
  ...StyleProps
}

const ClientOverview = ({ styles }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <Container className={styles.root} fluid>
      <div className={styles.titleWrap}>
        <h3 id="ClientOverview" className={styles.title}>
          <FormattedMessageSanitized {...messages.title} />
        </h3>
        <h4 className={styles.subtitle}>{formatMessage(messages.subTitle)}</h4>
      </div>
      <div className={styles.logoList}>
        <ClientLogo className={styles.logo} logo={logo6} />
        <ClientLogo className={styles.logo} logo={logo110} />
        <ClientLogo className={styles.logo} logo={logo102} />
        <ClientLogo className={styles.logo} logo={logo20} />
        <ClientLogo className={styles.logo} logo={logo109} />
        <ClientLogo className={styles.logo} logo={logo111} />
        <ClientLogo className={styles.logo} logo={logo71} />
        <ClientLogo className={styles.logo} logo={logo37} />
        <ClientLogo className={styles.logo} logo={logo75} />
        <ClientLogo className={styles.logo} logo={logo39} />
        <ClientLogo className={styles.logo} logo={logo101} />
        <ClientLogo className={styles.logo} logo={logo32} />
        <ClientLogo className={styles.logo} logo={logo106} />
        <ClientLogo className={styles.logo} logo={logoTk} />
        <ClientLogo className={styles.logo} logo={logo43} />
        <ClientLogo className={styles.logo} logo={logoDeichman} />
        <ClientLogo className={styles.logo} logo={logo57} />
        <ClientLogo className={styles.logo} logo={logo38} />
        <ClientLogo className={styles.logo} logo={logo108} />
        <ClientLogo className={styles.logo} logo={logo34} />
      </div>
      <Collapsible
        styles={styles.collapsible}
        isInitiallyOpen={false}
        trigger={
          <Button styles={styles.collapsibleBtn} color="primary">
            {formatMessage(messages.allClientsBtn)}
          </Button>
        }
      >
        <div className={styles.logoList}>
          <ClientLogo className={styles.logo} logo={logo94} />
          <ClientLogo className={styles.logo} logo={logo23} />
          <ClientLogo className={styles.logo} logo={logo59} />
          <ClientLogo className={styles.logo} logo={logo107} />
          <ClientLogo className={styles.logo} logo={logo4} />
          <ClientLogo className={styles.logo} logo={logo103} />
          <ClientLogo className={styles.logo} logo={logo104} />
          <ClientLogo className={styles.logo} logo={logo54} />
          <ClientLogo className={styles.logo} logo={logo1} />
          <ClientLogo className={styles.logo} logo={logo65} />
          <ClientLogo className={styles.logo} logo={logo7} />
          <ClientLogo className={styles.logo} logo={logo9} />
          <ClientLogo className={styles.logo} logo={logo78} />
          <ClientLogo className={styles.logo} logo={logo8} />
          <ClientLogo className={styles.logo} logo={logo3} />
          <ClientLogo className={styles.logo} logo={logo55} />
          <ClientLogo className={styles.logo} logo={logo13} />
          <ClientLogo className={styles.logo} logo={logo14} />
          <ClientLogo className={styles.logo} logo={logo15} />
          <ClientLogo className={styles.logo} logo={logo16} />
          <ClientLogo className={styles.logo} logo={logo17} />
          <ClientLogo className={styles.logo} logo={logo18} />
          <ClientLogo className={styles.logo} logo={logo12} />
          <ClientLogo className={styles.logo} logo={logo21} />
          <ClientLogo className={styles.logo} logo={logo22} />
          <ClientLogo className={styles.logo} logo={logo2} />
          <ClientLogo className={styles.logo} logo={logo25} />
          <ClientLogo className={styles.logo} logo={logo26} />
          <ClientLogo className={styles.logo} logo={logo27} />
          <ClientLogo className={styles.logo} logo={logo29} />
          <ClientLogo className={styles.logo} logo={logo30} />
          <ClientLogo className={styles.logo} logo={logo31} />
          <ClientLogo className={styles.logo} logo={logo33} />
          <ClientLogo className={styles.logo} logo={logo35} />
          <ClientLogo className={styles.logo} logo={logo10} />
          <ClientLogo className={styles.logo} logo={logo40} />
          <ClientLogo className={styles.logo} logo={logo41} />
          <ClientLogo className={styles.logo} logo={logo42} />
          <ClientLogo className={styles.logo} logo={logo44} />
          <ClientLogo className={styles.logo} logo={logo45} />
          <ClientLogo className={styles.logo} logo={logo46} />
          <ClientLogo className={styles.logo} logo={logo47} />
          <ClientLogo className={styles.logo} logo={logo48} />
          <ClientLogo className={styles.logo} logo={logo49} />
          <ClientLogo className={styles.logo} logo={logo50} />
          <ClientLogo className={styles.logo} logo={logo51} />
          <ClientLogo className={styles.logo} logo={logo52} />
          <ClientLogo className={styles.logo} logo={logo11} />
          <ClientLogo className={styles.logo} logo={logo53} />
          <ClientLogo className={styles.logo} logo={logo56} />
          <ClientLogo className={styles.logo} logo={logo24} />
          <ClientLogo className={styles.logo} logo={logo58} />
          <ClientLogo className={styles.logo} logo={logo60} />
          <ClientLogo className={styles.logo} logo={logo61} />
          <ClientLogo className={styles.logo} logo={logo62} />
          <ClientLogo className={styles.logo} logo={logo63} />
          <ClientLogo className={styles.logo} logo={logo64} />
          <ClientLogo className={styles.logo} logo={logo66} />
          <ClientLogo className={styles.logo} logo={logo67} />
          <ClientLogo className={styles.logo} logo={logo68} />
          <ClientLogo className={styles.logo} logo={logo69} />
          <ClientLogo className={styles.logo} logo={logo70} />
          <ClientLogo className={styles.logo} logo={logo72} />
          <ClientLogo className={styles.logo} logo={logo73} />
          <ClientLogo className={styles.logo} logo={logo74} />
          <ClientLogo className={styles.logo} logo={logo76} />
          <ClientLogo className={styles.logo} logo={logo77} />
          <ClientLogo className={styles.logo} logo={logo79} />
          <ClientLogo className={styles.logo} logo={logo80} />
          <ClientLogo className={styles.logo} logo={logo82} />
          <ClientLogo className={styles.logo} logo={logo84} />
          <ClientLogo className={styles.logo} logo={logo85} />
          <ClientLogo className={styles.logo} logo={logo86} />
          <ClientLogo className={styles.logo} logo={logo87} />
          <ClientLogo className={styles.logo} logo={logo88} />
          <ClientLogo className={styles.logo} logo={logo89} />
          <ClientLogo className={styles.logo} logo={logo90} />
          <ClientLogo className={styles.logo} logo={logo91} />
          <ClientLogo className={styles.logo} logo={logo92} />
          <ClientLogo className={styles.logo} logo={logo93} />
          <ClientLogo className={styles.logo} logo={logo95} />
          <ClientLogo className={styles.logo} logo={logo96} />
          <ClientLogo className={styles.logo} logo={logo97} />
          <ClientLogo className={styles.logo} logo={logo98} />
        </div>
      </Collapsible>
    </Container>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(ClientOverview)
