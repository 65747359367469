// @flow
import React from 'react'
import classNames from 'classnames'
import ScrollAnimation from 'react-animate-on-scroll'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import { Container, Row, Col } from '../../../components/basic/Grid'
import baseStyles from './ValueBox.scss'
import ValueBoxItem from './ValueBoxItem'

type Props = {
  ...StyleProps,
  productValues1: Object,
  productValues2: Object,
  productValues3: Object,
  icon1: string,
  icon2: string,
  icon3: string,
  title1: string,
  title2: string,
  title3: string,
  vertical?: boolean
}

const ValueBox = ({ styles, title1, title2, title3, icon1, icon2,
  icon3, productValues1, productValues2, productValues3, vertical }: Props) => {
  return (
    <div className={styles.root}>
      <Container className={styles.container} fluid>
        <Row className={classNames(styles.row, vertical && styles.vertical)}>
          <Col className={styles.col} xs="12" lg={!vertical && '4'}>
            <ScrollAnimation className={styles.animationContainer} animateIn="fadeInLeft" animateOnce delay={300}>
              <ValueBoxItem
                color="secondary"
                icon={icon1}
                title={title1}
                list={productValues1}
              />
            </ScrollAnimation>
          </Col>
          <Col className={styles.col} xs="12" lg={!vertical && '4'}>
            <ScrollAnimation className={styles.animationContainer} animateIn="fadeInUp" animateOnce delay={300}>
              <ValueBoxItem
                color="tertiary"
                icon={icon2}
                title={title2}
                list={productValues2}
              />
            </ScrollAnimation>
          </Col>
          <Col className={styles.col} xs="12" lg={!vertical && '4'}>
            <ScrollAnimation className={styles.animationContainer} animateIn="fadeInRight" animateOnce delay={300}>
              <ValueBoxItem
                color="accent"
                icon={icon3}
                title={title3}
                list={productValues3}
              />
            </ScrollAnimation>
          </Col>
        </Row>
      </Container>
    </div>
  )
}


export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(ValueBox)
