// @flow

import React, { useState } from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import { Container, Row, Col } from '../../../components/basic/Grid'
import { Spring } from 'react-spring/renderprops'
import classNames from 'classnames'
import ScrollAnimation from 'react-animate-on-scroll'
import baseStyles from './CompanyStats.scss'

type Props = {
  ...StyleProps,
  title: string,
  text: string,
  stats: Array<Object>,
  number?: number
}

const CompanyStats = ({ styles, title, stats, text }: Props) => {
  const { formatMessage } = useIntl()
  const [visible, setVisible] = useState(false)

  return (
    <div className={styles.root}>
      <Container className={styles.containerTop} fluid>
        <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: formatMessage(title) }} />
        <span className={styles.text} dangerouslySetInnerHTML={{ __html: formatMessage(text) }} />
      </Container>
      <div className={styles.companyRoot}>
        <ScrollAnimation
          className={styles.animationContainer}
          animateIn="fadeInUp"
          animateOnce
          duration={1}
          afterAnimatedIn={() => setVisible(true)}
        >
          <Container className={styles.container} fluid>
            <Row>
              {stats.map((item, i) => {
                return (
                  <Col xs="6" md="3" className={styles.col} key={'caseStat' + i}>
                    <div className={styles.animationContainer}>
                      <span className={classNames(styles.icon, item.icon, styles['is-' + item.color])} />
                      <span className={styles.number}>
                        {item.positive && '+'}
                        {item.number &&
                          (visible ? (
                            <Spring
                              from={{ number: 0 }}
                              to={{ number: item.number }}
                              config={{
                                precision: 0.1,
                                duration: 1000
                              }}
                            >
                              {props => (
                                <span>
                                  {props.number
                                    .toFixed(0)
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
                                </span>
                              )}
                            </Spring>
                          ) : (
                            <span>0</span>
                          ))}
                        {item.message && formatMessage(item.message)}
                        {item.numberOverride && item.numberOverride}
                        {item.numberDimension && <span>&nbsp;{formatMessage(item.numberDimension)}</span>}
                      </span>
                      <span className={styles.value}>{formatMessage(item.value)}</span>
                    </div>
                  </Col>
                )
              })}
            </Row>
          </Container>
        </ScrollAnimation>
      </div>
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(CompanyStats)
