// @flow

import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import cn from 'classnames'
import baseStyles from './ValueBoxItem.scss'

type Props = {
  ...StyleProps,
  title: string,
  icon: string,
  list?: Array<string>,
  color: string
}

const ValueBoxItem = ({ styles, list, color, title, icon }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <div className={styles.container}>
      <span className={cn(styles.iconTile, icon, styles[color])} />
      <span className={styles.title}>{title}</span>
      {list && (
        <div className={styles.list}>
          {list.map((item, i) => {
            return (
              <div className={styles.listItem} key={'valueBox' + item + i}>
                <span className={cn(styles.icon, 'icon-success')} />
                <span className={styles.listText}>{formatMessage(item)}</span>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(ValueBoxItem)
