// @flow

import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import Button from '../../basic/Button'
import ScrollTo from '../../basic/ScrollTo'
import { Container } from '../../basic/Grid'
import classNames from 'classnames'
import baseStyles from './BenefitsTeaserExtended.scss'
import { FormattedMessageSanitized } from '../../FormattedMessageSanitized'

type Props = {
  ...StyleProps,
  img: any,
  title: any,
  titleTwo?: any,
  text: any,
  textAdditional?: any,
  buttonText: any,
  buttonTextTwo: any,
  reversed?: boolean,
  list?: Array<string>,
  listTwo?: Array<string>
}

const BenefitsTeaserExtended = ({
  styles,
  img,
  title,
  titleTwo,
  text,
  textAdditional,
  list,
  listTwo,
  reversed,
  buttonText,
  buttonTextTwo
}: Props) => {
  const { formatMessage } = useIntl()

  return (
    <div className={classNames(styles.root, reversed && styles['is-reversed'])}>
      {reversed ? (
        <Container className={styles.container} fluid>
          <div className={styles.containerRight}>
            <span className={styles.text}>
              <h2 className={styles.title}>
                <FormattedMessageSanitized {...title} />
              </h2>
              <div className={styles.description}>
                <span>
                  <FormattedMessageSanitized {...text} />
                </span>
              </div>
              {list && (
                <ul className={styles.list}>
                  {list.map((item, i) => {
                    return (
                      <li className={styles.listitem} key={'benefitsTeaserExtended' + item + i}>
                        <span className={classNames(styles.checkIcon, 'icon-success')} />
                        {formatMessage(item)}
                      </li>
                    )
                  })}
                </ul>
              )}
            </span>
            <div className={styles.buttonRow}>
              <ScrollTo toId="Contact">
                <Button
                  styles={styles.btnOutline}
                  tag="span"
                  block
                  color="accent"
                  iconRight="icon-arrow_03-right-medium"
                >
                  {formatMessage(buttonTextTwo)}
                </Button>
              </ScrollTo>
            </div>
          </div>
          <aside className={styles.containerLeft}>
            <div className={styles.images}>
              <div className={styles.imageBig}>{img}</div>
            </div>
          </aside>
        </Container>
      ) : (
        <Container className={styles.container} fluid>
          <aside className={styles.containerLeft}>
            <div className={styles.images}>
              <div className={styles.imageBig}>{img}</div>
            </div>
          </aside>
          <div className={styles.containerRight}>
            <span className={styles.text}>
              <h2 className={styles.title}>
                <FormattedMessageSanitized {...title} />
              </h2>
              <div className={styles.description}>
                <span>
                  <FormattedMessageSanitized {...text} />
                </span>
              </div>
              {list && (
                <ul className={styles.list}>
                  {list.map((item, i) => {
                    return (
                      <li className={styles.listitem} key={'benefitsTeaser' + item + i}>
                        <span className={classNames(styles.checkIcon, 'icon-success')} />
                        {formatMessage(item)}
                      </li>
                    )
                  })}
                </ul>
              )}
            </span>
            <span className={styles.text}>
              <span>
                <FormattedMessageSanitized {...textAdditional} />
              </span>
              <h2 className={styles.titleAdditional}>
                <FormattedMessageSanitized {...titleTwo} />
              </h2>
            </span>
            {listTwo && (
              <ul className={styles.list}>
                {listTwo.map((item, i) => {
                  return (
                    <li className={styles.listitem} key={'benefitsTeaser' + item + i}>
                      <span className={classNames(styles.checkIcon, 'icon-success')} />
                      {formatMessage(item)}
                    </li>
                  )
                })}
              </ul>
            )}
            {/* <ScrollTo toId='Contact'>
              <Button
                styles={styles.btn}
                tag="span"
                block
                color="accent"
                iconRight="icon-arrow_03-right-medium"
              >
                {formatMessage(buttonText)}
              </Button>
            </ScrollTo> */}
          </div>
        </Container>
      )}
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(BenefitsTeaserExtended)
