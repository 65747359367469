// @flow

import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import classNames from 'classnames'
import baseStyles from './Landingpage.scss'
import messages from './Landingpage.lang'

type Props = {
  ...StyleProps
}

const LandingpageNav = ({ styles }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <div>
      <ul className={styles.navList}>
        <li className={classNames(styles.navItem, styles.navItemPhone)}>
          <a href="tel:+492119330110" className={styles.navLink} target="_blank" rel="noopener noreferrer">
            <span className={classNames(styles.icon, 'icon-mobile')} />
            {formatMessage(messages.phone)}
          </a>
        </li>
      </ul>
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(LandingpageNav)
