// @flow

import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import ScrollTo from '../../../components/basic/ScrollTo'
import messages from './CustomContactBtn.lang'
import Button from '../../../components/basic/Button'
import baseStyles from './CustomContactBtn.scss'

type Props = {
  ...StyleProps
}

const CustomContactBtn = ({ styles }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <div className={styles.root}>
      <ScrollTo toId="Contact">
        <Button styles={styles.contactBtn} tag="span" color="primary" rounded>
          {formatMessage(messages.Contact)}
        </Button>
      </ScrollTo>
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(CustomContactBtn)
