import { defineMessages } from 'react-intl'

/* eslint-disable max-len */

export default defineMessages({
  valueBoxTitle: 'Better software is not produced in Germany<b>?</b>',
  valueBoxSubtitle: 'You have 3 digital wishes free!',
  valueBoxTitle1: 'Apps',
  valueBoxTitle2: 'Websites',
  valueBoxTitle3: 'New systems - what the country needs!',
  valueBoxTitleTwo: 'Title',
  valueBoxSubtitleTwo: 'Subtitle',
  valueBoxTitle1Two: 'Digital transformation',
  valueBoxTitle2Two: 'Software audit',
  valueBoxTitle3Two: 'Web application development',
  strapiTitle: 'Better software is{br}not produced in Germany<b>?</b>',
  strapiText: 'Our working method sets new industry standards.{br}With us you are guaranteed to make the right decision.{br}{br}Where the best software is guaranteed not to be produced:<ul><li>in out-sourced junk shops</li><li>for digital do-it-yourselfers</li><li>in booby traps</li><li>the third cousin, who reinstalls your Windows for a pizza</li></ul>Forget digital patchwork dealers.{br}With us you get a digital future from one source.{br}"Other agencies talk rubbish. We supply stainless steel."',
  heroTitle: 'You want the <b>best agency</b> for your <b>software?</b>',
  heroSubtitle: 'Welcome to one of the most advanced software teams in Germany.',
  descriptionText: 'For years we have been solving even the most difficult software puzzles so that customers all over the world can relax and enjoy their holidays.{br}Example travel industry: Gigantic amounts of data in real time{br}<ul><li>Millions of flight data down to the last seat</li><li>Millions of flight data down to the last seat</li><li>Millions of hotel rooms down to the last detail</li><li>Millions of travel connections and additional offers,{br} all timed to the minute and constantly updated.</li><li>Everything is controlled, merged and presented via a user-friendly front end.</li></ul>Our methods have been formed over a decade in digital high-pressure projects and transferred to many industries. Over 500,000 hours of experience in the most complex and data-intensive system landscapes in Germany. {br}"Our experience is your advantage."',
  descriptionTitle: 'We solve highly complex projects<b>.</b>',
  descriptionButton: 'I want to be advised free of charge',
  contactTitle: 'When Bits calls<b>!</b>',
  contactSubtitle: 'Our well-coordinated teams of system engineers and project managers are waiting for your vision.{br}{br}One call is enough and your project is on the right track for implementation.{br}{br}Digital enthusiasm: From the first request to the last bit.{br}{br}Get free advice now!',
  contactheadline: 'Get <b>free advice</b> on Software Development now',
  benefitsText: 'The travel industry has the most complex data landscape in the world, even Google has not yet managed to integrate all of the worlds travel data and has to buy the data from external service providers.{br}{br}And us?{br}Have been optimizing our processes in this shark tank for over a decade.{br}{br}Our way of working used to be priceless - now we are just expensive. And every bit is worth gold.{br}{br}We do not tinker!{br}We are system engineers and build reliable high-performance systems with scientific precision.',
  benefitsTextAdditional: 'All from one source.{br}"Others try to live up to standards - standards we have set."',
  benefitsTitle: 'Better than Google<b>?</b>',
  benefitsTitleAdditional: 'We plan & develop software that inspires<b>:</b>',
  benefitsItemA: 'Websites and apps for your customers.',
  benefitsItemB: 'Backend systems for your employees.',
  benefitsItemC: 'Apps.',
  benefitsItemD: 'Websites.',
  benefitsItemE: 'New systems.',
  benefitsItemF: 'Old systems.',
  benefitsItemG: 'All at once? No problem.',
  benefitsButton: 'I want to be advised free of charge',
  productValues1A: 'We develop your mobile app.',
  productValues1B: 'We are professionals for the programming of mobile apps for iOS and Android.',
  productValues1C: 'We deliver well thought-out concepts, intuitive design and technical stability.',
  productValues1D: 'Take a look at our successful apps.',
  productValues2A: 'We develop your Web App.',
  productValues2B: 'We are professionals for the programming of Websites for all devices.',
  productValues2C: 'We deliver well thought-out concepts, intuitive Design and technical stability.',
  productValues2D: 'Take a look at our successful websites.',
  productValues3A: 'We bring your vision into reality.',
  productValues3B: 'No matter how complex it seems - we will just make it happen for you.',
  productValues3C: 'We deliver well thought-out concepts, intuitive design and technical stability.',
  productValues3D: 'We build future-proof systems according to the latest findings of software research.',
  productValues1ATwo: 'Visual UI / UX conception of your next project',
  productValues1BTwo: 'Technical evaluation of which TechStack is most useful for your project',
  productValues1CTwo: 'Content and functional conception. What is really the Minimum Viable Product',
  productValues2ATwo: 'Analysis of your current digital processes',
  productValues2BTwo: 'Suggestions for optimization through already used or new technologies',
  productValues2CTwo: 'Process optimization through targeted application development',
  productValues3ATwo: 'Analysis of your system landscape',
  productValues3BTwo: 'Optimization of hosting and infrastructure',
  productValues3CTwo: 'Customized software solutions with the highest standards',
  stat1Message: '+100 Customers',
  stat1Value: 'from the toughest industries in the world.',
  stat2Value: 'In Düsseldorf + worldwide network',
  dimensionYears: 'years',
  stat3Value: 'Stability & Quality',
  dimensionMillion: 'hours',
  dimensionOthers: 'employees',
  stat4Value: 'Experience in software',
  testAlpha: 'Our experience values',
  testBeta: '“Software rules the world. We master software”',
  benefitsTextExtended: 'No matter how many old systems and new ideas you want to bring under one hat: We have the solution. Welcome to one of the most experienced and advanced software teams in Germany. Your digital systems are threatening to fail? Here comes the cavalry! We will clean up and merge all your systems. Guarantees the right decision: we build future- proof, maintainable systems according to the latest findings of software research. You digital systems might fail? Here is the solution! We clean up and consolidate all your systems. Guaranteed to be the right decision: we build future-proof, maintainable systems according to the latest findings of software research.{br}{br}<b>Finally stable – forever!</b>{br}{br}No matter what you want to change in the future: our systems adapt to your vision.We are so progressive that our approach has coined a new term: context-adaptive systems. Sounds complex? It is. But that should not concern you; you will simply receive the result: digital success.',
  benefitsTitleExtended: 'Old systems - and new visions<b>?</b>',
  benefitsButtonExtended: 'To our showcases',
  benefitsButtonExtendedTwo: 'Get free advice now',
  TestModuleTitle: 'All at once<b>?</b>',
  TestModuleSubtitle: 'App + web + old systems + new visions = our everyday life.{br}{br}No matter how complex it appears - we simply implement it for you.{br}We deliver well thought- out concepts, intuitive design and technical stability.{br}Take a look at our successful projects.',
  TestModuleButtonOne: 'To our showcases',
  TestModuleButtonTwo: 'Get free advice now',
  TestModuleadditionalText: '"Tasks that seem too complex for other agencies are our daily business."',
  buttonText: 'To our references',
  link1: 'Apps',
  link2: 'Websites',
  link3: 'New systems',
  link4: 'System upgrades',
  link5: 'All at once? No problem!'
})
