// @flow

import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import Button from '../../../components/basic/Button'
import ScrollTo from '../../../components/basic/ScrollTo'
import { Container } from '../../../components/basic/Grid'
import baseStyles from './DescriptionTeaser.scss'
import { FormattedMessageSanitized } from '../../FormattedMessageSanitized'

type Props = {
  ...StyleProps,
  title: any,
  text: any,
  buttonText: any,
  imgBig: any,
  imgSmall1: any,
  displayButton: string,
  imgSmall2: any
}

const DescriptionTeaser = ({ styles, title, text, displayButton, imgBig, imgSmall1, imgSmall2, buttonText }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <div className={styles.root}>
      <Container className={styles.container} fluid>
        <div className={styles.containerLeft}>
          <h2 className={styles.title}>
            <FormattedMessageSanitized {...title} />
          </h2>
          <span className={styles.text}>
            <FormattedMessageSanitized {...text} />
          </span>
          {displayButton && (
            <ScrollTo toId="Contact">
              <Button styles={styles.btn} tag="span" block color="accent" iconRight="icon-arrow_03-right-medium">
                {formatMessage(buttonText)}
              </Button>
            </ScrollTo>
          )}
        </div>
        <aside className={styles.containerRight}>
          <div className={styles.images}>
            <div className={styles.imageBig}>{imgBig}</div>
            <div className={styles.imageSmall}>
              {imgSmall1}
              {imgSmall2}
            </div>
          </div>
        </aside>
      </Container>
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(DescriptionTeaser)
