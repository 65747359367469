// @flow

import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import ScrollAnimation from 'react-animate-on-scroll'
import LandingpageHeroTeaser from './LandingpageHeroTeaser'
import { Container } from '../basic/Grid'
import ClientOverview from './Clients/ClientOverview'
import Contact from './Contact'
import StrapiTeaser from '../Landingpage/StrapiTeaser'
import TestModule from './TestModule'
import DescriptionTeaser from '../Landingpage/DescriptionTeaser'
import ValueBox from '../Landingpage/ValueBox'
import BenefitsTeaser from './BenefitsTeaser'
import BenefitsTeaserExtended from './BenefitsTeaserExtended'
import CompanyStats from './CompanyStats'

import baseStyles from './Landingpage.scss'
import { FormattedMessageSanitized } from '../FormattedMessageSanitized'

type Props = {
  ...StyleProps,
  data: Object
}

const Landingpage = ({ styles, data }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <div className={styles.root}>
      <LandingpageHeroTeaser
        img={data.heroTeaser.img}
        title={data.heroTeaser.title}
        subtitle={data.heroTeaser.subtitle}
        headline={data.contact.headline}
        responsiveHeight={true}
        anchor1={'ValueItems'}
        anchor2={'ValueItems'}
        anchor3={'ValueItems'}
        anchor4={'Upgrades'}
        anchor5={'AllAtOnce'}
      />
      <ScrollAnimation animateIn="fadeInUp" animatePreScroll={true} animateOnce>
        <ScrollAnimation animateIn="fadeInUp" animatePreScroll={true} animateOnce>
          <ClientOverview expanded={data.referencesExpanded} />
        </ScrollAnimation>
        <CompanyStats
          className={styles.stats}
          title={data.companyStatsDescription.title}
          text={data.companyStatsDescription.text}
          stats={data.stats}
        />
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInUp" animatePreScroll={true} animateOnce>
        <DescriptionTeaser
          title={data.descriptionTeaser.title}
          text={data.descriptionTeaser.text}
          displayButton={data.descriptionTeaser.displayButton}
          buttonText={data.descriptionTeaser.button}
          imgBig={data.descriptionTeaser.imgBig}
          imgSmall1={data.descriptionTeaser.imgSmall1}
          imgSmall2={data.descriptionTeaser.imgSmall2}
        />
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInUp" animatePreScroll={true} animateOnce>
        <BenefitsTeaser
          reversed={data.benefitsTeaser.reversed}
          img={data.benefitsTeaser.img}
          title={data.benefitsTeaser.title}
          titleTwo={data.benefitsTeaser.titleTwo}
          text={data.benefitsTeaser.text}
          textAdditional={data.benefitsTeaser.additional}
          buttonText={data.benefitsTeaser.button}
          list={data.benefitsTeaser.list}
          listTwo={data.benefitsTeaser.listTwo}
        />
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInUp" animatePreScroll={true} animateOnce>
        <StrapiTeaser
          reversed={data.strapiTeaser.reversed}
          title={data.strapiTeaser.title}
          titlePlain={data.strapiTeaser.titlePlain}
          text={data.strapiTeaser.text}
          img={data.strapiTeaser.img}
        />
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInUp" animatePreScroll={true} animateOnce>
        <section id="ValueItems" className={styles.valueItems}>
          <Container className={styles.container} fluid>
            <h1 className={styles.title}>
              <FormattedMessageSanitized {...data.valueBox.title} />
            </h1>
            <h2 className={styles.subtitle}>{formatMessage(data.valueBox.subtitle)}</h2>
          </Container>
          <ValueBox
            className={styles.valueBox}
            productValues1={data.valueBox.values1}
            productValues2={data.valueBox.values2}
            productValues3={data.valueBox.values3}
            icon1="icon-pen-and-monitor"
            icon2="icon-idea"
            icon3="icon-complex"
            title1={formatMessage(data.valueBox.title1)}
            title2={formatMessage(data.valueBox.title2)}
            title3={formatMessage(data.valueBox.title3)}
          />
        </section>
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInUp" animatePreScroll={true} animateOnce>
        <div id="Upgrades">
          <BenefitsTeaserExtended
            reversed={data.benefitsTeaserExtended.reversed}
            img={data.benefitsTeaserExtended.img}
            title={data.benefitsTeaserExtended.title}
            text={data.benefitsTeaserExtended.text}
            buttonText={data.benefitsTeaserExtended.button}
            buttonTextTwo={data.benefitsTeaserExtended.buttonTwo}
            list={data.benefitsTeaserExtended.list}
          />
        </div>
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInUp" animatePreScroll={true} animateOnce>
        <div id="AllAtOnce">
          <TestModule
            reversed={data.TestModule.reversed}
            title={data.TestModule.title}
            text={data.TestModule.text}
            img={data.TestModule.img}
            buttonTextOne={data.TestModule.buttonOne}
            buttonTextTwo={data.TestModule.buttonTwo}
            additionalText={data.TestModule.additionalText}
          />
        </div>
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInUp" animatePreScroll={true} animateOnce>
        <section id="ValueItems" className={styles.valueItems}>
          <ValueBox
            className={styles.valueBox}
            productValues1={data.valueBoxTwo.values1}
            productValues2={data.valueBoxTwo.values2}
            productValues3={data.valueBoxTwo.values3}
            icon1="icon-pen-and-monitor"
            icon2="icon-idea"
            icon3="icon-complex"
            title1={formatMessage(data.valueBoxTwo.title1)}
            title2={formatMessage(data.valueBoxTwo.title2)}
            title3={formatMessage(data.valueBoxTwo.title3)}
          />
        </section>
      </ScrollAnimation>
      <section id="Contact" className={styles.contactBottom}>
        <ScrollAnimation animateIn="fadeInUp" animatePreScroll={true} animateOnce>
          <Contact title={data.contact.title} subtitle={data.contact.subtitle} headline={data.contact.headline} />
        </ScrollAnimation>
      </section>
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(Landingpage)
