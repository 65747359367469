// @flow

import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import Button from '../../basic/Button'
import { Container } from '../../basic/Grid'
import baseStyles from './TestModule.scss'
import classNames from 'classnames'
import ScrollTo from '../../basic/ScrollTo'

type Props = {
  ...StyleProps,
  title: string,
  text: string,
  img: any,
  buttonTextOne: any,
  buttonTextTwo: any,
  additionalText: any,
  reversed?: boolean
}

const TestModile = ({ styles, title, buttonTextOne, buttonTextTwo, additionalText, img, text, reversed }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <div className={classNames(styles.root, reversed && styles['is-reversed'])}>
      {reversed ? (
        <Container className={styles.container} fluid>
          <aside className={styles.containerRight}>
            <div className={styles.images}>
              <div className={styles.imageBig}>{img}</div>
            </div>
          </aside>
          <div className={styles.containerLeft}>
            <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: formatMessage(title) }} />
            <span className={styles.text} dangerouslySetInnerHTML={{ __html: formatMessage(text) }} />
            <div className={styles.buttonRow}>
              <ScrollTo toId="Contact">
                <Button
                  styles={styles.btnOutline}
                  tag="span"
                  block
                  color="accent"
                  iconRight="icon-arrow_03-right-medium"
                >
                  {formatMessage(buttonTextTwo)}
                </Button>
              </ScrollTo>
            </div>
            <span
              className={styles.additionalText}
              dangerouslySetInnerHTML={{ __html: formatMessage(additionalText) }}
            />
          </div>
        </Container>
      ) : (
        <Container className={styles.container} fluid>
          <div className={styles.containerLeft}>
            <h2 className={styles.title} dangerouslySetInnerHTML={{ __html: formatMessage(title) }} />
            <span className={styles.text} dangerouslySetInnerHTML={{ __html: formatMessage(text) }} />
            <Button>test1</Button>
            <Button>test2</Button>
          </div>
          <aside className={styles.containerRight}>
            <div className={styles.images}>
              <div className={styles.imageBig}>{img}</div>
            </div>
          </aside>
        </Container>
      )}
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(TestModile)
