// @flow

import React from 'react'
import classNames from 'classnames'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import { useIntl } from 'gatsby-plugin-intl'
import ContactForm from '../../ContactPage/ContactForm'
import { Container } from '../../../components/basic/Grid'
import baseStyles from './LandingpageHeroTeaser.scss'
import ScrollTo from '../../../components/basic/ScrollTo'
import messages from '../Landingpage.lang'
import { FormattedMessageSanitized } from '../../FormattedMessageSanitized'

type Props = {
  ...StyleProps,
  preHeadlines?: any,
  responsiveHeight?: boolean,
  img: any,
  anchor1: string,
  anchor2: string,
  anchor3: string,
  anchor4: string,
  anchor5: string,
  title: any,
  subtitle: any,
  headline: any
}

const HeroTeaser = ({
  styles,
  headline,
  anchor1,
  anchor2,
  anchor3,
  anchor4,
  anchor5,
  preHeadlines,
  title,
  subtitle,
  img,
  responsiveHeight
}: Props) => {
  const { formatMessage } = useIntl()

  return (
    <div className={classNames(styles.root, responsiveHeight && styles['responsiveHeight'])}>
      <div className={styles.imageWrap}>
        <div className={styles.image}>{img}</div>
      </div>
      {preHeadlines}
      <div className={styles.headlines}>
        <Container className={styles.container} fluid>
          <div className={styles.left}>
            <div className={styles.titleWrap}>
              <h2 className={styles.title}>
                <FormattedMessageSanitized {...title} />
              </h2>
              <p className={styles.subtitle}>
                <FormattedMessageSanitized {...subtitle} />
              </p>
              <ul className={styles.list}>
                <li className={styles.listItem}>
                  <ScrollTo toId={anchor1} styles={styles.btnLink}>
                    {formatMessage(messages.link1)}
                  </ScrollTo>
                </li>
                <li className={styles.listItem}>
                  <ScrollTo toId={anchor2} styles={styles.btnLink}>
                    {formatMessage(messages.link2)}
                  </ScrollTo>
                </li>
                <li className={styles.listItem}>
                  <ScrollTo toId={anchor3} styles={styles.btnLink}>
                    {formatMessage(messages.link3)}
                  </ScrollTo>
                </li>
                <li className={styles.listItem}>
                  <ScrollTo toId={anchor4} styles={styles.btnLink}>
                    {formatMessage(messages.link4)}
                  </ScrollTo>
                </li>
                <li className={styles.listItem}>
                  <ScrollTo toId={anchor5} styles={styles.btnLink}>
                    {formatMessage(messages.link5)}
                  </ScrollTo>
                </li>
              </ul>
            </div>
          </div>
          <div className={styles.right}>
            <ContactForm formClassName={'contactTop'} formHeadline={headline} />
          </div>
        </Container>
      </div>
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(HeroTeaser)
